<template>
	<div>
		<v-row class="mt-2">
			<v-col cols="12" class="pa-6 pt-1 pb-0">
				<pui-switch
					:label="$t('operations.movementsequence.checkboxMovement')"
					v-model="show"
					color="var(--primary)"
					class="my-auto my-0 py-0 pb-5"
				></pui-switch>
			</v-col>
		</v-row>
	</div>
</template>

<script>
/** Components */
import Item from '@/components/operations/map/sections/map/subcomponents/MapLegendDialogItem';

export default {
	props: ['panelOptions'],
	components: {
		Item
	},

	data() {
		return {
			showMovement: false
		};
	},

	mounted() {},

	destroyed() {},

	methods: {
		setPanelOptionsData() {
			this.showMovement = this.panelOptions.seqMovementVis;
		}
	},

	computed: {
		show: {
			get() {
				return this.showMovement;
			},

			set(value) {
				// Update panel data
				this.showMovement = value;
				this.panelOptions.seqMovementVis = value;
				this.$store.commit('updateDynamicTabData', this.panelOptions);
				this.$store.commit('updateActivePanelAIS', this.panelOptions);
			}
		}
	},
	watch: {
		panelOptions() {
			this.setPanelOptionsData();
		}
	}
};
</script>

<style lang="postcss">
.track {
	background-color: #ddecf6;
	margin: 2px;
}
.v-label--required {
	font-weight: bold;
}
</style>
