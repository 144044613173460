<template>
	<div>
		<v-row class="mt-2">
			<v-col cols="8" class="ml-6">
				<div class="text-h6 font-weight-black">{{ title }}</div>
			</v-col>
			<v-col cols="2" class="ml-6">
				<v-btn @click="closePanel" depressed icon height="32" width="32" class="closebtn ml-auto" :title="closeTitleBtn">
					<v-icon size="0.9rem">far fa-times</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<!-- Track -->
		<track-vessel :panelOptions="panelOptions"></track-vessel>

		<!-- Movement Sequence -->
		<movement-sequence v-if="showMovementSequence" :panelOptions="panelOptions"></movement-sequence>
	</div>
</template>

<script>
/** Components */
import TrackVessel from './TrackVessel';
import MovementSequence from './MovementSequence';
import { INSTANT_FUTURE_PANEL_ID } from '@/lib/variables/panels';

export default {
	props: ['stop', 'closePanel', 'panelOptions'],

	components: {
		TrackVessel,
		MovementSequence
	},

	methods: {
		toLowerCaseLessFirstLetter(str) {
			return str[0].toUpperCase() + str.slice(1).toLowerCase();
		}
	},

	computed: {
		title() {
			var text = this.stop.vesselname || this.stop.name;
			if (text && text.length > 0) {
				return this.toLowerCaseLessFirstLetter(text);
			}
			return text;
		},

		closeTitleBtn() {
			return this.$t('common.closeTitleBtn');
		},

		showMovementSequence() {
			return (
				((this.panelOptions.instantPanel === INSTANT_FUTURE_PANEL_ID || this.panelOptions.instantPanel === null) &&
					!this.panelOptions.data.isAis) ||
				(this.panelOptions.data.isAis && this.panelOptions.data.portCallNumber)
			);
		}
	}
};
</script>

<style lang="postcss">
.closebtn {
	background-color: var(--low);

	& i {
		color: var(--primary);
	}
	&:hover {
		& i {
			color: var(--mapfre-accent);
		}
	}
}
.v-application .text-h6 {
	font-family: Overpass, Montserrat, sans-serif !important;
}
</style>
