<template>
	<div v-if="type == 'requesteds' && isCurrentMapPeriods">
		<autho-plan-header :closePanel="closePanel" :type="type"></autho-plan-header>
		<autho-plan-content :closePanel="closePanel" :stop="{ ...options.data }" :type="type"></autho-plan-content>
	</div>
	<div v-else-if="type == 'planning' && isCurrentMapPeriods">
		<autho-plan-header :closePanel="closePanel" :type="type"></autho-plan-header>
		<autho-plan-content :closePanel="closePanel" :stop="{ ...options.data }" :type="type"></autho-plan-content>
	</div>
	<div v-else>
		<stop-header :stop="{ ...options.data }" :closePanel="closePanel" :panelOptions="options"></stop-header>
		<stop-content :stop="{ ...options.data }"></stop-content>
	</div>
</template>

<script>
import StopHeader from './StopHeader.vue';
import StopContent from './StopContent.vue';
import AuthoPlanHeader from './AuthoPlanHeader.vue';
import AuthoPlanContent from './AuthoPlanContent.vue';
import PanelMixin from '@/mixins/PanelMixin';

import { MAP_PERIODS_ID } from '@/components/operations/map/sections/map/subcomponents/mapVariables';

export default {
	components: { StopHeader, StopContent, AuthoPlanHeader, AuthoPlanContent },

	mixins: [PanelMixin],

	props: {
		options: {
			type: Object,
			required: true
		},
		currentmap: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: true
		}
	},

	created() {},

	computed: {
		isCurrentMapPeriods() {
			return this.currentmap == MAP_PERIODS_ID;
		}
	}
};
</script>
