<template>
	<span class="operationsField" :style="{ display: inline ? 'flex' : '' }">
		<b class="font-weight-black text-subtitle-2">{{ label }}:</b> <br />
		<p class="font-weight-regular text-subtitle-2" :style="{ 'margin-left': inline ? '10px' : '' }">{{ value }} {{ suffix }}</p>
	</span>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			required: true
		},
		value: {
			type: [String, Number],
			default: ''
		},
		inline: {
			type: Boolean,
			default: false
		},
		suffix: {
			type: String,
			required: false
		}
	}
};
</script>

<style>
.operationsField {
	font-size: 12px;
}
.v-application .text-subtitle-2 {
	font-family: Overpass, Montserrat, sans-serif !important;
}
</style>
