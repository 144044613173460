import { MAP_AIS_ID } from '@/components/operations/map/sections/map/subcomponents/mapVariables';

export default {
	props: {
		options: {
			type: Object,
			required: true
		},
		currentmap: {
			type: String,
			required: true
		}
	},
	computed: {
		visible() {
			if (this.currentmap == MAP_AIS_ID) {
				return this.$store.getters.activePanelAIS === this.options.panelId;
			}
			return this.$store.getters.activePanelPeriods === this.options.panelId;
		},

		closeTitleBtn() {
			return this.$t('common.closeTitleBtn');
		}
	},
	methods: {
		closePanel() {
			if (this.currentmap == MAP_AIS_ID) {
				this.$store.commit('removeDynamicPanelAisByOptionsPanel', this.options);
			} else {
				this.$store.commit('removeDynamicPanelPeriodsByOptionsPanel', this.options);
			}
		}
	}
};
