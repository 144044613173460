<template>
	<div class="dynamicpanels fill-height">
		<stop-panel :options="activeDynamicPanel" :visible="true" :currentmap="currentmap" :type="getType()"></stop-panel>
	</div>
</template>

<script>
import StopPanel from '../stop/StopPanel.vue';
import { MAP_AIS_ID } from '@/components/operations/map/sections/map/subcomponents/mapVariables';
import {
	STOP_ACCEPTED_LAYER_ID,
	STOP_PLANNED_LAYER_ID,
	STOP_AUTHORIZED_LAYER_ID,
	STOP_CONFIRMED_LAYER_ID,
	STOP_INITIATED_LAYER_ID,
	STOP_INSTANT_LAYER_ID,
	AIS_VESSEL_LAYER_ID
} from '@/components/operations/map/constants/layers';

const PANEL_MAP = {
	[STOP_ACCEPTED_LAYER_ID]: 'stop',
	[STOP_PLANNED_LAYER_ID]: 'stop',
	[STOP_AUTHORIZED_LAYER_ID]: 'stop',
	[STOP_CONFIRMED_LAYER_ID]: 'stop',
	[STOP_INITIATED_LAYER_ID]: 'stop',
	[STOP_INSTANT_LAYER_ID]: 'stop',
	[AIS_VESSEL_LAYER_ID]: 'stop'
};

export default {
	name: 'dynamicpanels',

	components: { StopPanel },

	props: {
		currentmap: {
			type: String,
			required: true
		}
	},

	methods: {
		getType() {
			if (this.currentmap == MAP_AIS_ID) {
				return this.$store.getters.panelParentActive;
			} else {
				return this.$store.getters.activePanelOptionsPeriods.instantPanel;
			}
		}
	},

	computed: {
		panels() {
			if (this.currentmap == MAP_AIS_ID) {
				return this.$store.getters.dynamicPanelsAIS;
			}
			return this.$store.getters.dynamicPanelsPeriods;
		},

		activePanel() {
			if (this.currentmap == MAP_AIS_ID) {
				return this.$store.getters.activePanelAIS;
			}
			return this.$store.getters.activePanelPeriods;
		},

		activeDynamicPanel() {
			return this.panels.find(({ panelId }) => panelId === this.activePanel);
		}
	}
};
</script>
